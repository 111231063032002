@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.topBorder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
}

.warning {
  background: $canopy-color-primitives-yellow-72;
}

.discovery {
  background: $canopy-color-primitives-purple-87;
}

.cardContent {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid $canopy-color-border-default;
  background: $canopy-color-background-secondary;
  box-shadow: 0 2px 4px 0 rgba(34, 48, 54, 0.14);
  overflow: hidden;
  position: relative;
  width: 20rem;
  &:hover {
    background: $canopy-color-interactive-background-secondary-hover;
    border-color: $canopy-color-border-form-control-hover;
    box-shadow: none;
  }
  &:active {
    background: $canopy-color-interactive-background-secondary-active;
  }
}
