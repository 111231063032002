@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.thirdPartyLoginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  .thirdPartyLoginButton {
    margin-bottom: $canopy-space-8x;
    border: 2px solid $canopy-color-primitives-gray-74;
    color: $canopy-color-text-primary !important;
    width: 440px; /*  matches magic value from sign-up.css */
    justify-content: center;

    span {
      display: flex;
      align-items: center;
    }

    img {
      height: auto;
      max-height: 24px;
      width: 24px;
      vertical-align: text-bottom;
      margin-right: $canopy-space-4x;
    }
  }
}
